import React from "react"
import Link from "../common/Link"

export default function RightsDownloads({ downloads }) {
  return (
    <div>
      <div className="space-y-8 bg-primary/20   p-4">
        <h3 className="text-2xl font-bold">Downloads</h3>
        <div className="space-y-2">
          {downloads.map(download => {
            return <Download key={download.title} download={download} />
          })}
        </div>
      </div>
    </div>
  )
}

function Download({ download }) {
  console.log(download)
  return (
    <div className="flex flex-row space-x-2 text-sm lg:text-base ">
      <div>
        <Link className="hover:underline" to={download.file.mediaItemUrl}>
          {download.title}
        </Link>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-primary "
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      </div>
    </div>
  )
}
