import React from "react"

export default function RightsImportantDates({ importantDates }) {
  return (
    <div className="space-y-8 bg-primary bg-opacity-20  p-4">
      <h3 className="text-2xl font-bold">Important Dates</h3>
      <div className="space-y-2">
        {importantDates.map(date => {
          return <ImportantDate key={date.title} date={date} />
        })}
      </div>
    </div>
  )
}

function ImportantDate({ date }) {
  return (
    <div className="text-sm lg:text-base flex flex-row justify-between ">
      <div>{date.title}</div>
      <div>{date.dateinfo}</div>
    </div>
  )
}
