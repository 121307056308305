import React from "react"
import PageHeader from "../../components/common/PageHeader"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import HeaderFeaturedImage from "../../components/common/HeaderFeaturedImage"
import { func } from "prop-types"
import RightsImportantDates from "../../components/financial/RightsImportantDates"
import RightsDownloads from "../../components/financial/RightsDownloads"

export default function FinancialReportsPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 381 }) {
        id
        title
        content
        rightsIssues {
          disclaimer
          disclaimerHeadline
          downloads {
            title
            file {
              mediaItemUrl
            }
          }
          importantDates {
            title
            dateinfo
          }
        }
      }
    }
  `)
  const { title, featuredImage, content } = data.wpPage
  const { disclaimer, disclaimerHeadline, downloads, importantDates } =
    data.wpPage.rightsIssues
  const [notice, setNotice] = React.useState(true)
  const [confirm, setConfirm] = React.useState(false)
  return (
    <Layout>
      <SEO title="Simris Announces a Capital Raise comprising of a Rights Issue and a Directed Issue" />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div
          id="financial-reports"
          className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8"
        >
          <div className="flex flex-col gap-8">
            {!featuredImage ? <PageHeader text={title} /> : null}
            <di>
              {notice && (
                <div className="bg-primary/10 p-8">
                  <Disclaimer
                    disclaimer={disclaimer}
                    disclaimerHeadline={disclaimerHeadline}
                  />
                  <div className="flex flex-row space-x-4 mt-8">
                    <button
                      className="uppercase font-bold lg:text-xl px-3 py-2 lg:px-6 lg:py-3 mt-4 inline-block rounded-md bg-primary text-white"
                      onClick={() => setNotice(false)}
                    >
                      Yes, I accept
                    </button>
                    <button
                      onClick={() => setConfirm(true)}
                      className="uppercase font-bold lg:text-xl px-3 py-2 lg:px-6 lg:py-3 mt-4 inline-block rounded-md bg-primary text-white"
                    >
                      No, I don't accept
                    </button>
                  </div>
                  <div className="text-sm pt-4">
                    {confirm &&
                      "You cannot access the content without confirming that you have read and understood the disclaimer."}
                  </div>
                </div>
              )}
            </di>
            <>
              {!notice && (
                <div className="flex flex-col gap-8">
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: content }}
                      className="wp-content"
                    />
                  </div>
                  <div className="grid lg:grid-cols-2 gap-8">
                    <div>
                      <RightsImportantDates importantDates={importantDates} />
                    </div>
                    <div>
                      <RightsDownloads downloads={downloads} />
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Disclaimer({ disclaimer, disclaimerHeadline }) {
  return (
    <div className="">
      <div className="space-y-4 text-sm">
        <h2 className="text-xl font-bold">
          {disclaimerHeadline || "Important Notice"}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
      </div>
    </div>
  )
}
